export const useInvoice = () => {
  const invoiceColumns = [
    { type: 'primary-text', label: 'Datum', labelKey: 'date', itemKey: 'invoiceDate', colSpan: 1.2 },
    { type: 'text', label: 'Factuur', labelKey: 'invoiceNumber', itemKey: 'invoiceNumber', colSpan: 4.5 },
    { type: 'text', label: 'Bedrag', labelKey: 'amount', itemKey: 'netInvoiceAmountInclTax', colSpan: 0.9 },
    { type: 'primary-slot', label: '', labelKey: 'paymentStatus', itemKey: 'paymentStatus', colSpan: 0.8, align: 'right' }
  ]

  const invoiceOrderColumns = [
    { name: 'deliveryDate', title: 'Leverdatum', width: '20%' },
    { name: 'orderNumber', title: 'Ordernummer', width: '32%', type: 'primary' },
    { name: 'reference', title: 'Referentie', width: '48%' }
  ]

  return { invoiceColumns, invoiceOrderColumns }
}
